@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #d2d2d2;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #f37216;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c25b12;
}
